
import Link from "next/link";
export default function CustomButton(props) {
  return (
    <Link href={(props.href ? props.href : "#")} locale={props.locale}
          className={`btn ${props.btnClassName ? props.btnClassName : ''} ${props.isBack ? "p-start" : "" || props.isGo ? "p-end" : ""}`}>
        {(!props.isBack && !props.isGo) ? props.name : null} &nbsp;
        {props.isSubscribe ?
          props.currLocale && props.currLocale === 'ar' ?
            <svg width="20" height="12" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.96792 1.07954C7.33665 0.496816 6.35252 0.536176 5.7698 1.16745L0.744157 6.61183C0.194117 7.2077 0.194111 8.12615 0.744145 8.72203L5.76979 14.1665C6.3525 14.7978 7.33663 14.8372 7.96791 14.2545C8.59919 13.6718 8.63856 12.6876 8.05585 12.0563L5.44001 9.22249L20.5538 9.22249C21.4129 9.22249 22.1094 8.52604 22.1094 7.66693C22.1094 6.80783 21.4129 6.11138 20.5538 6.11138L5.44007 6.11138L8.05584 3.27766C8.63856 2.64639 8.5992 1.66226 7.96792 1.07954Z" fill="white" />
            </svg> :
            <svg width="20" height="12" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.4735 1.07954C15.1048 0.496816 16.0889 0.536176 16.6716 1.16745L21.6972 6.61183C22.2473 7.2077 22.2473 8.12615 21.6973 8.72203L16.6716 14.1665C16.0889 14.7978 15.1048 14.8372 14.4735 14.2545C13.8422 13.6718 13.8028 12.6876 14.3856 12.0563L17.0014 9.22249L1.88758 9.22249C1.02848 9.22249 0.33203 8.52604 0.33203 7.66693C0.33203 6.80783 1.02848 6.11138 1.88758 6.11138L17.0013 6.11138L14.3856 3.27766C13.8029 2.64639 13.8422 1.66226 14.4735 1.07954Z" fill="white" />
            </svg>
          : null}
        {props.isBack &&
          <>
            <svg id="icons_Star_Filled_Copy" data-name="icons/Star/Filled Copy" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
              <path id="vector_Stroke_" data-name="vector (Stroke)" d="M7,5.786,9.157,3.452a.667.667,0,0,0,0-.9L7,.214a.667.667,0,0,0-.98.9L7.144,2.333H.667a.667.667,0,1,0,0,1.333H7.144L6.023,4.881a.667.667,0,1,0,.98.9Z" transform="translate(12.667 11) rotate(180)" fill="#23262F" />
            </svg>
            {props.name} 
          </>
        }
        {props.isGo &&
          <>
            {props.name}
            <svg width={10} height={7} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.39412 0.843309C6.66467 0.593571 7.08644 0.61044 7.33618 0.880985L9.49003 3.21429C9.72576 3.46967 9.72576 3.86329 9.49003 4.11867L7.33619 6.45203C7.08645 6.72257 6.66468 6.73945 6.39413 6.48971C6.12358 6.23998 6.10671 5.81821 6.35644 5.54766L7.47752 4.33315L1.00016 4.33315C0.631972 4.33315 0.333496 4.03467 0.333496 3.66648C0.333496 3.29829 0.631972 2.99982 1.00016 2.99982L7.47749 2.99982L6.35645 1.78536C6.10671 1.51482 6.12358 1.09305 6.39412 0.843309Z" fill="#23262F" />
            </svg>
          </>
        }
      </Link>
  )
}
